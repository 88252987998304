.header {
    background-color: var(--bg-color);
    color: #efefef;
    padding: 0 0 0 1rem;
    p {
        font-size: 1.6rem;
        font-family: 'Noto Sans', sans-serif;
        letter-spacing: 0.1em;
        line-height: 0.4em;
    }
}

.header-container {
    padding: var(--padding-container);
    display: flex;
    align-items: center;
}
.img-logo {
    padding: 0.4em 1em 0.4em 0;
}
.show-params {
    font-size: 0.8em;
}
