.MuiPaper-root {
    color: var(--color-blue);
    background-color: inherit;
}
.MuiCard-root {
    padding: var(--padding-sub-container);
    margin: var(--padding-sub-container);
    margin-bottom: 0.5rem;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    &:hover {
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }
}

.MuiCardActions-root {
    display: block;
    border-top: 1px #e1e1e1 solid;
    background-color: #e9e9e9;
}

/* laptops */
@media only screen and (min-width: 62rem) {
    .MuiCard-root {
        width: var(--width-break-laptop);
    }
}
