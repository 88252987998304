@import './variables.scss';

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    //border-bottom: 1px solid $c-gold;
    //box-shadow: 0 1px 0 0 $c-gold;
    margin: 0 0 1em 0;
}

input[type='text'] {
    //font-size: 2em !important;
    margin-bottom: 0.2em !important;
}

input[type='checkbox'].filled-in:checked + span:not(.lever):after {
    border: 2px solid $c-gold-dark;
    background-color: $c-gold;
}

// .btn,
// .btn-large,
// .btn-small {
//     background-color: rgba($color: $c-light, $alpha: 0.9);
// }

// .btn:hover,
// .btn-large:hover,
// .btn-small:hover {
//     background-color: rgba($color: $c-gold, $alpha: 0.6);
// }

// .btn:focus,
// .btn-large:focus,
// .btn-small:focus,
// .btn-floating:focus {
//     background-color: $c-blue-darker;
// }

.fixed-action-btn {
    z-index: 0;
}

.select-wrapper {
    > * {
        color: rgba($color: $c-dark, $alpha: 0.8);
    }
    .caret {
        fill: rgba($color: $c-dark, $alpha: 0.8);
    }
}

.select-wrapper input.select-dropdown:focus {
    border-bottom: 1px solid $c-gold;
}
.dropdown-content li > a,
.dropdown-content li > span {
    color: $c-dark;
}

@media #{$small-and-down} {
    input[type='text'] {
        font-size: 1.2em !important;
    }
}
