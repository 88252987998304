@import url('https://fonts.googleapis.com/css?family=Noto+Sans|Poppins');

body {
    margin: 0;
    color: var(--color);
    background-color: var(--bg-color);
    font-family: var(--font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@import './entry-point';

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.container {
    background-color: #efefef;
}

input:focus,
textarea:focus {
    outline: none;
}

input::-webkit-input-placeholder {
    color: #aaa;
}

input:-moz-placeholder {
    color: #aaa;
}

.background-logo {
    position: absolute;
    overflow: hidden;
    bottom: 0;
    right: -300px;
    z-index: 0;
    opacity: 0.5;
    visibility: hidden;
}

.go-right {
    float: right;
}

.view-json a {
    text-decoration: none;
    color: inherit;
}

.brighten {
    margin-bottom: 3em;
}

@media only screen and (min-width: 62rem) {
    .background-logo {
        visibility: visible;
    }
}
