.search-container {
    padding: var(--padding-container);
    font-family: var(--font-family);
}

.search-controls {
    //display: flex;
    //justify-content: space-between;
    margin: 0.5em;
}

.url-params {
    align-self: flex-end;
    text-align: left;
    //width: 80%;
    p {
        //padding: 1em;
        span {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-grow: 1;
            label {
                flex-grow: 0;
            }
            input {
                flex-grow: 1;
            }
        }
    }
    .param-filter {
        font-family: var(--font-family);
        background-color: #ddd;
        padding: 0.2em;
        margin: 0.2em;
        border: none;
        font-size: inherit;
        color: inherit;
        width: 66%;
        &:focus {
            background-color: #ccc;
            transition: background-color 500ms;
            border: none;
        }
    }
}
.more-than-zero {
    background-color: var(--color-highlight);
    transition: background-color 1s;
}
.results-total {
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding: 0.4em;
    p {
        padding: 1em;
    }
}

.result-meta {
    font-size: 0.8em;
    //font-style: italic;
    font-family: 'Quicksand';
    letter-spacing: 0.02rem;
    padding-bottom: 1em;
}

.result-pagination {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 2em;
    > .btn {
        min-width: 30%;
        height: 2em;
        // min-width: 22%;
        // max-width: 25%;
        line-height: 2em;
        flex-basis: 100px;
        align-content: center;

        align-items: center;
    }
    > .result-pagination-msg {
        flex: 1;
    }
}

.boldy {
    font-weight: bold;
    padding-left: 0.6em;
}

.result-pagination-msg {
    vertical-align: middle;
    //text-align: center;
    margin-top: 1em;
    font-style: italic;
}

/* laptops */
@media only screen and (min-width: 62rem) {
    .search-controls,
    .result-pagination,
    .result-pagination-msg {
        width: var(--width-break-laptop);
    }
}
